<script>
  // Components
  import GreatAlbums from './GreatAlbums.svelte'
  import Library from './Library.svelte'
  import Footer from './Footer.svelte'

  // App vars
  let page = 'great-albums'

  // Routing
  import Navigo from 'navigo'
  const router = new Navigo('', true)
  router.on({
    '/great-albums': () => {
      page = 'great-albums'
    },
    '/library': () => {
      page = 'library'
    },
    '*': () => {
      page = 'great-albums'
    }
  }).resolve()
</script>

<svelte:head>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-10824270-9"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-10824270-9');
  </script>
</svelte:head>

<style>
  .app {
    max-width: 800px;
    padding: 15px;
  }

  h1 {
    margin-top: 0;
  }

  header ul {
    display: flex;
    padding: 0;
  }

  header ul li {
    list-style-type: none;
    margin-right: 10px;
  }

  header ul li button.highlighted {
    background-color: #ddd;
  }

  .intro {
    margin: 10px 0 30px 0;
  }

  .intro span {
    display: block;
  }
</style>

<div class="app">
  <h1>JP ♫ Music</h1>

  <header>
    <ul>
      <li>
        <button
          on:click={() => router.navigate('/great-albums')}
          class:highlighted="{page === 'great-albums'}"
        >
          Great albums
        </button>
      </li>
      <li>
        <button
          on:click={() => router.navigate('/library')}
          class:highlighted="{page === 'library'}"
        >
          Library
        </button>
      </li>
    </ul>
  </header>

  {#if page === 'library'}
    <div class="intro">
      <span>Explore my songs library!</span>
      <span>I'm not proud of everything that's there, but that's all part of my musical journey 🙂</span>
    </div>
    <Library />
  {:else}
    <div class="intro">
      <span>Great albums, not necessarily new, that I (re)discovered recently.</span>
      <span>One album per week maximum.</span>
    </div>
    <GreatAlbums />
  {/if}
</div>

<Footer />
