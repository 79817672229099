<script>
  import { onMount } from 'svelte'
  import SearchResult from './components/SearchResult.svelte'

  import algoliasearch from 'algoliasearch/lite'

  const appId = '6B29BTPQED'
  const apiKey = '49ae085a83962db19658af549b3bac7e'
  const indexName = 'prod_music_library'
  let searchClient
  let index
  let query = ''
  let hits = []
  let groupByAlbum = false

  onMount(() => {
    searchClient = algoliasearch(appId, apiKey)
    index = searchClient.initIndex(indexName)

    search()
  })

  async function search() {
    const results = await index.search(query, { distinct: groupByAlbum })
    hits = results.hits
  }

  $: groupByAlbum, search()
</script>

<style>
  .search-box {
    position: relative;
    margin: 0 0 10px 0;
  }

  .search-box input {
    width: 100%;
    padding-left: 30px;
  }

  .search-icon {
    z-index: 1;
    position: absolute;
    top: 25%;
    left: .6rem;
    width: 14px;
    height: 14px;
    fill: #666;
  }
</style>

<div class="search-box">
  <input bind:value={query} on:keyup={search} type="text" placeholder="Search artist, album, genre, year...">
  <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 40 40"> <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path> </svg>
</div>
<div class="search-options">
  <label>
    <input type="checkbox" bind:checked={groupByAlbum}>
    Group by album
  </label>
</div>

<div class="search-results">
  {#each hits as hit (hit.objectID)}
    <SearchResult {hit} />
  {/each}
</div>
