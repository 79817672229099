<script>
  export let album

  const cover_url = album.cover_url.replace('upload/', 'upload/w_140,c_scale/')
  const added = album.added.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
</script>

<style>
  .album {
    display: flex;
    margin-bottom: 20px;
  }

  .album:last-child {
    margin-bottom: 0;
  }

  .cover {
    margin-right: 10px;
  }

  .album-details div {
    margin: 5px 0;
  }

  .album-details .date {
    margin-top: 0;
  }

  .album-details .title {
    margin: 5px 0;
    font-size: 1.6em;
  }
</style>

<div class="album">
  <div class="cover">
    <img src="{cover_url}" alt="{album.title}">
  </div>
  <div class="album-details">
    <div class="date">{added}</div>
    <div class="title">{album.title}</div>
    <div class="artist">{album.artist}</div>
    <div class="year">{album.year}</div>
    <div class="genre">{album.genre}</div>
  </div>
</div>
