<script>
  import { db } from './firebase-init'
  import Album from './components/Album.svelte'

  const pageSize = 5
  const oldestAlbumId = '2020W16'
  let firstAlbum = null
  let pageFirstAlbum = null
  let pageLastAlbum = null
  let isFirstPage = true
  let isLastPage = false
  let promise = getAlbums()

  async function getAlbums(reverse = false) {
    let query = db
      .collection('great_albums')
      .where('added', '<=', new Date())
      .orderBy('added', 'desc')

    if (reverse && pageFirstAlbum) {
      query = query.endBefore(pageFirstAlbum).limitToLast(pageSize)
    }
    else if (pageLastAlbum) {
      query = query.startAfter(pageLastAlbum).limit(pageSize)
    }
    else {
      query = query.limit(pageSize)
    }

    return query.get().then(snapshot => {
      // set pagination metadata
      if (!firstAlbum) {
        firstAlbum = snapshot.docs[0]
      }
      pageFirstAlbum = snapshot.docs[0]
      pageLastAlbum = snapshot.docs[snapshot.docs.length - 1]
      isFirstPage = snapshot.docs[0].id === firstAlbum.id
      isLastPage = pageLastAlbum.id === oldestAlbumId

      // retrieve albums data
      const promises = []
      snapshot.forEach(document => {
        const id = document.id
        const added = document.data().added.toDate()
        const promise = document.data().album.get().then(album => ({id, added, ...album.data()}))
        promises.push(promise)
      })

      promise = Promise.all(promises) // albums
    })
    .catch(console.log)
  }
</script>

<div class="great-albums">
  {#await promise}
    <p>Loading albums...</p>
  {:then albums}
    {#each albums as album, i}
      <Album {album} />
    {/each}
    <button on:click={() => getAlbums(true)} disabled={isFirstPage || undefined}>Previous</button>
    <button on:click={() => getAlbums()} disabled={isLastPage || undefined}>Next</button>
  {:catch error}
    <p>Error loading album</p>
  {/await}
</div>
