<script>
  export let hit

  const cover_url = hit.cover_url.replace('upload/', 'upload/w_50,c_scale/')
</script>

<style>
  .hit {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #eee;
  }

  .hit:last-child {
    margin-bottom: 0;
  }

  .hit-details div {
    margin: 5px 0;
  }

  .hit-details .title {
    margin-top: 0;
    font-size: 1.3em;
  }

  :global(em) {
    font-style: normal;
    border-bottom: 1px solid black;
  }

  .extras {
    text-align: right;
  }

  .extras .play-count {
    margin-top: 4px;
  }

  .extras .loved {
    margin-top: 10px;
  }
</style>

<div class="hit">
  <div class="hit-details">
    <div class="title">{@html hit._highlightResult.title.value}</div>
    <div class="album">{@html hit._highlightResult.album.value}</div>
    <div class="artist">{@html hit._highlightResult.artist.value}</div>
    <div class="year">{hit.year}</div>
    <div class="genre">{@html hit._highlightResult.genre.value}</div>
  </div>
  <div class="extras">
    <img src="{cover_url}" alt="{hit.title}">
    <div class="play-count">{hit.play_count ? `${hit.play_count} 🎧` : ''}</div>
    <div class="loved">{hit.loved ? `🖤` : ''}</div>
  </div>
</div>
