import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/analytics"
// import "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyD3W1AtwTRrPY55znsb-9KK0DBamk00PtQ",
  authDomain: "music-jupa.firebaseapp.com",
  databaseURL: "https://music-jupa.firebaseio.com",
  projectId: "music-jupa",
  storageBucket: "music-jupa.appspot.com",
  messagingSenderId: "566004208582",
  appId: "1:566004208582:web:b852e70db9fe5bfbe5d8c7",
  measurementId: "G-MKJM8D2EKD"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const db = firebase.firestore()
